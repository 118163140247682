<template>
 <div class="flex h-full relative">
  <div class="z-20 fixed top-32 right-5 rounded-xl cursor-pointer">
   <ArrowCircleLeftIcon
    v-if="!showDetail"
    @click="showDetail = true"
    class="h-8 w-8 text-gray-500 hover:text-gray-600"
    aria-hidden="true"
   />
  </div>
  <!-- Content area -->
  <div class="relative flex flex-1 flex-col overflow-hidden">
   <!-- Main content -->
   <div class="bg-white relative flex flex-1 items-stretch overflow-hidden">
    <main class="flex-1 overflow-y-auto">
     <div class="mx-auto">
      <section class="border border-gray-300 p-1" aria-labelledby="gallery-heading">
       <div>
        <iframe
         v-if="materialType === 'video' && fileToShow"
         width="100%"
         height="600px"
         class="w-full aspect-video"
         :src="fileToShow"
        ></iframe>
        <!-- <video v-if="materialType === 'video' && fileToShow" width="100%" height="600px" controls>
         <source :src="fileToShow" :type="mymeType" />
        </video> -->
        <object v-else width="1200" :height="maxHeight" :data="fileToShow" id="myFile"></object>
       </div>
      </section>
      <section aria-labelledby="applicant-information-title">
       <div class="bg-white">
        <div class="flex justify-between items-center px-4 sm:px-6"></div>
        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
         <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-1">
           <dt class="text-sm font-medium text-gray-500 capitalize">
            {{ $t("course") }}
           </dt>
           <dd class="mt-1 text-sm text-gray-900">
            {{ courseDetail.name }}
           </dd>
          </div>
          <div class="sm:col-span-1">
           <dt class="text-sm font-medium text-gray-500 capitalize">
            {{ $t("price") }}
           </dt>
           <dd class="mt-1 text-sm text-gray-900">
            {{ courseDetail.free ? "Free" : "$" + courseDetail.price }}
           </dd>
          </div>
          <!-- <div class="sm:col-span-2">
           <dt class="text-sm font-medium text-gray-500 capitalize">
            {{ $t("excerpt") }}
           </dt>
           <dd class="mt-1 text-sm text-gray-900">
            <span v-html="courseDetail.excerpt"></span>
           </dd>
          </div> -->
          <div class="sm:col-span-2">
           <dt class="text-sm font-medium text-gray-500 capitalize">
            {{ $t("description") }}
           </dt>
           <dd class="mt-1 text-sm text-gray-900">
            <span v-html="courseDetail.description"></span>
           </dd>
          </div>
          <div class="sm:col-span-2" v-if="courseDetail.materials && courseDetail.materials.length">
           <dt class="text-sm font-medium text-gray-500 capitalize">
            {{ $t("materials") }}
           </dt>
           <dd class="mt-1 text-sm text-gray-900">
            <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
             <li
              v-for="attachment in courseDetail.materials"
              :key="attachment.name"
              class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
             >
              <div class="flex w-0 flex-1 items-center">
               <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
               <span class="ml-2 w-0 flex-1 truncate">{{ attachment.title }}</span>
              </div>
              <div class="flex flex-1 items-center">
               <span class="flex-1 truncate text-gray-500">{{ attachment.filename }}</span>
              </div>
              <div class="ml-4 flex-shrink-0 capitalize flex gap-1 text-sm items-center">
               <a
                @click="openFile(attachment.id, attachment.mimetype)"
                class="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
                >Download</a
               >
              </div>
             </li>
            </ul>
           </dd>
          </div>
         </dl>
        </div>
       </div>
      </section>
     </div>
    </main>

    <!-- Details sidebar -->
    <aside
     v-if="showDetail"
     class="relative w-80 overflow-y-auto border-l border-gray-200 bg-white lg:block"
    >
     <div @click="showDetail = false" class="fixed rounded-xl cursor-pointer">
      <ArrowCircleRightIcon
       v-if="showDetail"
       class="h-8 w-8 text-gray-500 hover:text-gray-600"
       aria-hidden="true"
      />
     </div>
     <div class="fixed aside__max-height top-20 bg-white pb-2 w-80 px-4">
      <progress-bar :percentage="courseProgress * 100" />
      <div class="space-y-6 pb-16">
       <div class="border-b border-gray-200 bg-white py-5">
        <h3 class="text-lg font-medium leading-6 text-gray-900 capitalize">{{ $t("lessons") }}</h3>
       </div>
       <div class="overflow-hidden shadow sm:rounded-md">
        <ul role="list" class="divide-y divide-gray-200">
         <li class="" v-for="lesson in lessons" :key="lesson.id">
          <a class="hover:bg-gray-50 cursor-pointer">
           <div class="relative flex items-center px-4 py-4 sm:px-6">
            <input
             type="checkbox"
             :value="lesson.id"
             :checked="lesson.completed_at"
             @change="lesson.completed_at ? uncompleteLesson(lesson.id) : completeLesson(lesson.id)"
             class="absolute left-2 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <div class="flex min-w-0 flex-1 items-center" @click="getLesson(lesson.id)">
             <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
               <p class="text-sm font-medium text-gray-600">
                {{ $t("lesson") }} {{ lesson.order }}
               </p>
              </div>
              <div class="hidden md:block">
               <div>
                <p class="text-sm text-gray-900">
                 {{ lesson.title }}
                </p>
               </div>
              </div>
             </div>
            </div>
            <div>
             <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
           </div>
          </a>
         </li>
        </ul>
       </div>
       <div class="py-2 px-1.5 space-y-1">
        <h1 class="text-gray-800 font-medium text-lg">
         Lesson description
        </h1>
        <p class="text-gray-600 text-md">
         {{ lessonData.text }}
        </p>
       </div>
       <!-- <div class="flex">
        <button
         @click="this.$router.push(`/training/courses/course-detail/${courseDetail.id}`)"
         type="button"
         class="flex-1 rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none body__button"
        >
         {{ $t("goTo") }}
        </button>
       </div> -->
      </div>
     </div>
    </aside>
   </div>
  </div>
 </div>
</template>

<script>
import ProgressBar from "../../components/ProgressBar.vue";
import axios from "axios";
import {
 CheckCircleIcon,
 ChevronRightIcon,
 EnvelopeIcon,
 ArrowCircleLeftIcon,
 ArrowCircleRightIcon,
} from "@heroicons/vue/solid";
export default {
 props: ["id"],
 components: {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
  ProgressBar,
 },
 data() {
  return {
   fileToShow: undefined,
   materialType: undefined,
   mymeType: "",
   courseDetail: {},
   lessons: [],
   lessonData: {},
   showDetail: true,
   maxHeight: "600",
   courseProgress: 0,
  };
 },
 methods: {
  getCourseDetail() {
   console.log("file: CoursePreview.vue ~ line 111 ~ getCourseDetail ~ this.id", this.id);
   this.$emit("setLoading", true);
   const url = `api/v1/training/courses/${this.id}`;
   axios
    .get(`${this.$cookie.getCookie("API")}/${url}`)
    .then((res) => {
     this.courseDetail = res.data;
     //  this.lessons = res.data.lessons.sort((a, b) => {
     //   return a.order - b.order;
     //  });
     //  this.getLesson(this.lessons[0].id);
     this.getLessons();
     console.log("file: Courses.vue ~ line 450 ~ getCourseDetail ~ res.data", res.data);
     this.$emit("setLoading", false);
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("setLoading", false);
    });
  },
  getLessons() {
   const url = `api/v1/training/lessons?course_id=${this.id}`;
   axios
    .get(`${this.$cookie.getCookie("API")}/${url}`)
    .then((res) => {
     this.lessons = res.data;
     this.getLesson(res.data[0].id);
     let completedLessons = res.data.filter((lesson) => {
      return lesson.completed_at;
     });
     this.courseProgress = completedLessons.length / res.data.length;
     console.log("file: TrainingCourse.vue ~ line 241 ~ .then ~ res.data", res.data);
     this.$emit("setLoading", false);
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("setLoading", false);
    });
  },
  completeLesson(id) {
   const url = `api/v1/training/lessons/${id}/complete`;
   axios
    .post(`${this.$cookie.getCookie("API")}/${url}`)
    .then((res) => {
     console.log("file: TrainingCourse.vue ~ line 256 ~ .then ~ res", res.data);
     this.getLessons();
     this.$emit("setLoading", false);
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("setLoading", false);
    });
  },
  uncompleteLesson(id) {
   const url = `api/v1/training/lessons/${id}/uncomplete`;
   axios
    .put(`${this.$cookie.getCookie("API")}/${url}`)
    .then((res) => {
     console.log("file: TrainingCourse.vue ~ line 256 ~ .then ~ res", res.data);
     this.getLessons();
     this.$emit("setLoading", false);
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("setLoading", false);
    });
  },
  async getFile(id, mimeType) {
   this.$emit("setLoading", true);
   console.log("file: CoursePreview.vue ~ line 133 ~ getFile ~ id, mimeType", id, mimeType);
   let url = `${this.$cookie.getCookie(
    "API"
   )}/api/v1/training/materials/${id}/content?as_attachment=0`;
   const res = await axios
    .get(url, {
     responseType: "arraybuffer",
    })
    .catch((err) => {
     this.errorHandling(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
     this.$emit("setLoading", false);
    });
   const fileBlob = new Blob([await res.data], { type: mimeType });
   let resizedFile = await this.resizeImage({
    file: fileBlob,
    maxSize: { width: "1100", height: "1200" },
   });
   const fileURL = URL.createObjectURL(resizedFile);
   this.fileToShow = fileURL;
   this.$emit("setLoading", false);
  },
  getLesson(id) {
   this.fileToShow = undefined;
   let url = `${this.$cookie.getCookie("API")}/api/v1/training/lessons/${id}`;
   axios
    .get(url)
    .then((res) => {
     this.lessonData = res.data;
     if (res.data && res.data.materials && res.data.materials.length > 0) {
      console.log(
       "file: CoursePreview.vue ~ line 263 ~ .then ~ res.data.materials[0].exteral",
       res.data.materials[0].external
      );
      !res.data.materials[0].external
       ? this.getFile(res.data.materials[0].id, res.data.materials[0].mimetype)
       : (this.fileToShow = res.data.materials[0].external_url);
      this.materialType = res.data.materials[0].type;
      this.mymeType = res.data.materials[0].mimetype;
     }
     console.log("file: LessonDetail.vue ~ line 21 ~ axios.get ~ res.data", res.data);
    })
    .catch((error) => {
     this.errorHandling(error);
    });
  },
  dataURItoBlob(dataURI) {
   const bytes =
    dataURI.split(",")[0].indexOf("base64") >= 0
     ? atob(dataURI.split(",")[1])
     : unescape(dataURI.split(",")[1]);
   const mime = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
   const max = bytes.length;
   const ia = new Uint8Array(max);
   for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
   return new Blob([ia], { type: mime });
  },
  resizeImage({ file, maxSize }) {
   const reader = new FileReader();
   const image = new Image();
   const canvas = document.createElement("canvas");

   const resize = () => {
    let { width, height } = image;

    if (width > maxSize.width) {
     width = maxSize.width;
    }
    if (height > maxSize.height) {
     height = maxSize.height;
    }
    console.log("file: CoursePreview.vue ~ line 276 ~ resize ~ height", height);
    this.maxHeight = height;

    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(image, 0, 0, width, height);

    const dataUrl = canvas.toDataURL("image/jpeg");

    return this.dataURItoBlob(dataUrl);
   };

   return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
     no(new Error("Not an image"));
     return;
    }

    reader.onload = (readerEvent) => {
     image.onload = () => ok(resize());
     image.src = readerEvent.target.result;
    };

    reader.readAsDataURL(file);
   });
  },
 },
 created() {
  this.getCourseDetail();
 },
};
</script>

<style>
.show__file {
 object-fit: cover;
 overflow: hidden;
}
</style>
